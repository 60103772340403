<template>
  <div class="full-bg full-center" :style="`width: ${props.width}px !important; height: ${props.height}px !important;`">
    <div style="display: block">
      <img :src="`${Config.api.url}assets/LINE_ORIGINAL_ACTBOIS.png`" alt="..." class="rounded-circle logo-size">
      <h1 class="title-delagotic fs-h1 color-primary">Nous arrivons bientôt ...</h1>
      <h1 class="title-delagotic fs-countdown color-white">{{ dateString }}</h1>
    </div>
  </div>
</template>

<script>
const Config = require("../apiconfig.json")

export default {
  name: "Countdown",
  data: () => {
    return {
      Config,
      props: {
        width: 0,
        height: 0
      },
      date: new Date(Config.env.release),
      now: new Date(),
      dateString: ""
    }
  },
  methods: {
    check: function() {
      if (this.date.getTime()-this.now.getTime() < 0) {
        window.location.href = "https://actbois.org/"
      }
    },
    calc: function() {
      this.now = new Date()
      let moment = new Date(this.date.getTime()-this.now.getTime())
      this.check()
      this.dateString = `${moment.getDate()-1 < 10 ? "0"+moment.getDate()-1 : moment.getDate()-1}j, ${moment.getHours()-1 < 10 ? "0"+moment.getHours()-1 : moment.getDate()-1}h ${moment.getMinutes() < 10 ? "0"+moment.getMinutes() : moment.getMinutes()}min ${moment.getSeconds() < 10 ? "0"+moment.getSeconds() : moment.getSeconds()}s`
      setTimeout(() => {
        this.calc()
      }, 1000)
    }
  },
  mounted() {
    this.props.height = window.innerHeight
    this.props.width = window.innerWidth

    window.addEventListener("resize", () => {
      this.props.height = window.innerHeight
      this.props.width = window.innerWidth
    })

    this.check()
    this.calc()
  }
}
</script>

<style src="../../public/modules/bootstrap/css/bootstrap.min.css"></style>
<style src="../../public/modules/fonts/font-awesome.min.css"></style>
<style src="../../public/modules/fontawesome/css/all.min.css"></style>

<style scoped>
  @font-face {
    font-family: "Dela Gothic One Regular";
    src: url("../../public/modules/fonts/DelaGothicOne-Regular.ttf") format("truetype")
  }

  .full-bg {
    width: 100% !important;
    height: 100% !important;
    background-color: #433E42 !important;
  }

  .color-primary {
    color: #C8D43F !important;
  }

  .color-white {
    color: #F8F9FA !important;
  }

  .title-delagotic {
    font-family: "Dela Gothic One Regular", serif !important;
  }

  .logo-size {
    width: 15rem;
    height: 15rem;
  }

  @media screen and (min-width: 1001px) {
    .fs-h1 {
      font-size: 4rem;
    }

    .fs-countdown {
      font-size: 7rem;
    }

    .full-center {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: 1000px) {
    .fs-h1 {
      font-size: 1.7rem;
    }

    .fs-countdown {
      font-size: 3rem;
    }

    .full-center {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
    }
  }
</style>