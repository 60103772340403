import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Projects from "@/views/Projects";
import ProjectView from "@/views/ProjectView";
import Countdown from "@/views/Countdown";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: "/projects/:id",
    name: "ProjectView",
    component: ProjectView
  },
  {
    path: "/projects",
    name: "Projects",
    component: Projects
  },
  {
    path: "/countdown",
    name: "Countdown",
    component: Countdown
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
