<template>
  <nav class="navbar navbar-dark navbar-expand-lg fixed-top bg-dark" id="mainNav">
    <div class="container">
      <router-link class="navbar-brand title-delagotic" style="font-size: 40px; color: #C7D43F" to="/#page-top">
        <img :src="`${Config.api.url}assets/LOGO.png`" style="height: 100px; width: 100px;" alt="...">
        Act' Bois
      </router-link>
<!--      <a class="navbar-brand title-delagotic" href="#page-top" v-else>-->
<!--        <img :src="`${Config.api.url}assets/LOGO.png`" style="height: 50px; width: 50px;" alt="...">-->
<!--        Act' Bois-->
<!--      </a>-->
      <button data-bs-toggle="collapse" data-bs-target="#navbarResponsive" class="navbar-toggler navbar-toggler-right" type="button" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fas fa-bars"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ms-auto text-uppercase">
          <li class="nav-item">
            <router-link :class="`nav-link ${$route.fullPath.includes('sumup') ? 'btn btn-actbois current-tab' : ''}`" to="/#sumup">
              <i class="fas fa-vector-square"></i>&nbsp;
              Présentation
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :class="`nav-link ${$route.fullPath.includes('history') ? 'btn btn-actbois current-tab' : ''}`" to="/#history">
              <i class="far fa-address-card"></i>&nbsp;
              À propos
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :class="`nav-link ${$route.fullPath.includes('team') ? 'btn btn-actbois current-tab' : ''}`" to="/#team">
              <i class="fas fa-users"></i>&nbsp;
              L'Équipe
            </router-link>
          </li>
          <li class="nav-item" v-if="!$route.name.includes('Project')">
            <router-link :class="`nav-link ${$route.fullPath.includes('projects') ? 'btn btn-actbois current-tab' : ''}`" to="/#projects">
              <i class="fas fa-tasks"></i>&nbsp;
              Projets
            </router-link>
          </li>
          <li class="nav-item" v-else>
            <router-link :class="`nav-link ${$route.fullPath.includes('projects') ? 'btn btn-actbois current-tab' : ''}`" to="/projects#ALL">
              <i class="fas fa-tasks"></i>&nbsp;
              Projets
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
const Config = require("../../apiconfig.json")

export default {
  name: "Navbar",
  data: () => {
    return {
      // winLocation: "",
      Config
    }
  },
  watch: {
    $route() {
      let section = this.$router.currentRoute.hash.replace("#", "");
      if (section && !this.$route.name.includes("Project")) this.$nextTick(()=> window.document.getElementById(section).scrollIntoView());
    }
  },
  mounted() {
    // this.winLocation = window.location.href
    //
    // window.addEventListener("hashchange", () => {
    //   this.winLocation = window.location.href
    // })
  }
}
</script>

<style src="../../../public/modules/bootstrap/css/bootstrap.min.css"></style>
<style src="../../../public/modules/fonts/font-awesome.min.css"></style>
<style src="../../../public/modules/fontawesome/css/all.min.css"></style>

<style scoped>
  @font-face {
    font-family: "Dela Gothic One Regular";
    src: url("../../../public/modules/fonts/DelaGothicOne-Regular.ttf") format("truetype")
  }

  .title-delagotic {
    font-family: "Dela Gothic One Regular", serif !important;
  }

  .current-tab {
    font-weight: bold !important;
  }
</style>