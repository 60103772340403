<template>
  <footer id="footerApp">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <span class="copyright" style="line-height: unset"><i class="far fa-copyright"></i>&nbsp;Copyright © Act'Bois 2021 {{ new Date().getFullYear().toString() !== "2021" ? "- " + new Date().getFullYear() : "" }}<br><i><i class="fas fa-pencil-alt"></i>&nbsp;Créé par <a href="https://romainmunier.fr" class="text-actbois" target="_blank">Romain MUNIER</a></i></span>
        </div>
        <div class="col-md-4">
          <ul class="list-inline social-buttons">
            <li class="list-inline-item">
              <a @click="openInstaModal" class="text-white">
                <i class="fab fa-instagram"></i>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-4">
          <ul class="list-inline quicklinks">
            <li class="list-inline-item">
              <a href="tel:+33384237281" class="text-actbois" style="text-decoration: none">
                <i class="fas fa-phone-alt" style="text-decoration: none"></i>&nbsp;
                <span style="text-decoration: underline">+33 3 84 23 72 81</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="modal fade" id="instaModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
<!--          <div class="modal-header">-->
<!--            <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>-->
<!--            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
<!--          </div>-->
          <div class="modal-body">
            <h1 class="title-delagotic text-black-50">Rejoignez-nous sur Instagram</h1>

            <div class="d-block m-3 p-1">
              <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                <div id="instafeed" class="carousel-inner">

                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Précédent</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Suivant</span>
                </button>
              </div>

            </div>

            <div class="d-flex flex-row-reverse align-content-center align-items-center mt-4 me-3">
              <button type="button" class="btn btn-outline-dark position-relative btn-sm" @click="goToInstagram">
                Plus de publications
                <span class="position-absolute top-0 start-100 translate-middle p-2 bg-dark text-light border border-light rounded-circle d-flex justify-content-center align-items-center">
                  <i class="fab fa-instagram"></i>
                </span>
              </button>
              <button type="button" class="btn btn-actbois btn-sm me-1" data-bs-dismiss="modal">Fermer</button>
              <!--              <button class="btn btn-outline-dark align-content-center align-items-center d-flex">-->
<!--                <span class="badge bg-light me-2">-->
<!--                  <i class="fab fa-instagram text-dark fs-2"></i>-->
<!--                </span>-->
<!--                Plus de publications-->
<!--              </button>-->
            </div>
          </div>
<!--          <div class="modal-footer">-->
<!--            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
<!--            <button type="button" class="btn btn-primary">Save changes</button>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
const Bootstrap = require("bootstrap")

export default {
  name: "FooterSection",
  methods: {
    goToInstagram: function() {
      window.open("https://www.instagram.com/actbois/", "_blank").focus()
    },
    openInstaModal: function() {
      document.getElementById("instafeed").children[0].className = "carousel-item active"

      let modal = new Bootstrap.Modal(document.getElementById("instaModal"))
      modal.show()
    }
  },
  mounted() {
    // new Bootstrap.Popover(document.getElementById("phonePopover"), {
    //   animation: true,
    //   content: "TEST"
    // })

    let instafeed = document.createElement("script")
    instafeed.src = "https://api.actbois.org/libs/instafeed.min.js"
    document.getElementsByTagName("body")[0].appendChild(instafeed)

    instafeed.onload = function() {
      let script = document.createElement("script")

      script.innerText =
          `new Instafeed({
          accessToken: "IGQVJYeDNXdThTR0ZA4VWtrY0djT3dFaTh0SUJNVmkxSEZAWcGlSa0FiUkc5cDlWX1hqR0RlQW83MXZAzSWR0REkwWjVxcE9vS3Rxb1ByclZApZAGs2bHlqNmNhakhLaEVLWlpFQjdzOGYtQ2tLOU9ZATDlrLQZDZD",
          template: "<div class='carousel-item'>
                    <img src='{{ image }}' style='height: ${window.innerHeight/1.3}px; object-fit: cover; width: 100%;' class='d-block w-100' alt='{{ caption }}'>
                    <div class='carousel-caption d-none d-md-block bg-light rounded' style='background-color: rgba(248, 249, 250, 0.6) !important;'>
                      <h5></h5>
                      <p style='color: #000000;'>{{ caption }}</p>
                    </div>
                  </div>"
          }).run()`
      document.getElementsByTagName("body")[0].appendChild(script)
    };
  }
}
</script>

<style scoped>
.title-delagotic {
  font-family: "Dela Gothic One Regular", serif !important;
  font-size: 2rem;
}

.fs-2 {
  font-size: 2rem;
}
</style>