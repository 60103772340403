<template>
  <div>
    <div class="card card-body align-left" v-if="project._pictures !== undefined && project._flag !== undefined && parseInt(project._pictures.length)+parseInt(project._flag.length) > 0">
      <div class="row align-items-center align-content-center">
        <div class="col-lg-12 align-left">
          <h4 class="title-delagotic mb-3">
            <i class="fas fa-images"></i>&nbsp;
            Galerie photos
          </h4>
          <div class="row justify-content-center align-content-center align-items-center">
            <div class="col-lg-4 d-flex justify-content-center picture-hover" v-for="(picture, fIndex) of project._flag" :key="`FLAG-${fIndex}`" @click="openCarousel(picture.picture)" :style="`margin: 1px; padding: 0; background-image: url('${Config.api.url}referencespictures/${picture.picture}'); background-size: cover; with: 100%; max-width: 100%; height: auto; min-height: 20rem;`">
<!--              <img class="picture-hover" :src="`${Config.api.url}referencespictures/${picture.picture}`" alt="..." style="max-width: 100%; height: auto; object-fit: cover">-->
            </div>
            <div class="col-lg-4 d-flex justify-content-center picture-hover" v-for="(picture, pIndex) of project._pictures" :key="`PICTURE-${pIndex}`" @click="openCarousel(picture.picture)" :style="`margin: 1px; padding: 0; background-image: url('${Config.api.url}referencespictures/${picture.picture}'); background-size: cover; with: 100%; max-width: 100%; height: auto; min-height: 20rem;`">
<!--              <img class="picture-hover" :src="`${Config.api.url}referencespictures/${picture.picture}`" alt="..." style="max-width: 100%; height: auto; object-fit: cover">-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="pictures-carousel" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-dialog-centered bg-transparent">
        <div class="modal-content bg-transparent border-0" style="max-height: 100%;">
          <div class="modal-body" style="max-height: 100%;">
            <div id="pictures-div-carousel" class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-indicators">
                <!--                <button type="button" :data-bs-target="`#MODAL-FLAG-${f2Index}`" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1" v-for="(picture, f2Index) of project._flag" :key="`BUTTON-FLAG-${f2Index}`"></button>-->
              </div>
              <div class="carousel-inner">
                <div :class="`carousel-item ${project._flag.indexOf(picture) === 0 ? 'active' : ''}`" v-for="(picture, fIndex) of project._flag" :key="`MODAL-FLAG-${fIndex}`" :id="`MODAL-FLAG-${fIndex}`">
                  <img :src="`${Config.api.url}referencespictures/${picture.picture}`" class="d-block w-100" alt="...">
                  <div class="carousel-caption d-none d-md-block">
                    <h5>{{ picture.credit !== "" && picture.credit !== undefined && picture.credit !== null ? picture.credit.toUpperCase() : project._pictureCredit.toUpperCase() }}</h5>
                  </div>
                </div>
                <div :class="`carousel-item`" style="max-height: 100%;" v-for="(picture, pIndex) of project._pictures" :key="`MODAL-PICTURE-${pIndex}`" :id="`MODAL-FLAG-${pIndex}`">
                  <img :src="`${Config.api.url}referencespictures/${picture.picture}`" class="d-block w-100" alt="..." :style="`max-height: ${maxHeight}px; object-fit: contain`">
                  <div class="carousel-caption d-none d-md-block">
                    <h5>{{ picture.credit !== "" && picture.credit !== undefined && picture.credit !== null ? picture.credit.toUpperCase() : project._pictureCredit.toUpperCase() }}</h5>
                  </div>
                </div>

              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" @click="previousSlide">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Précédent</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" @click="nextSlide">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Suivant</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
// eslint-disable-next-line no-unused-vars
import $ from "jquery"

const Config = require("../../../apiconfig.json")

import { Modal } from "bootstrap"

export default {
  name: "Gallery",
  data: () => {
    return {
      Config,
      project: {},
      carouselModal: null,
      maxHeight: 0
    }
  },
  methods: {
    previousSlide() {
      let id = $('.carousel-item').toArray().indexOf($('.carousel-item').toArray().find(c => c.className.includes("active")))

      $($('.carousel-item').get(id)).removeClass("active")
      $($('.carousel-item').get((id-1)%$('.carousel-item').length)).addClass("active")
    },
    nextSlide() {
      let id = $('.carousel-item').toArray().indexOf($('.carousel-item').toArray().find(c => c.className.includes("active")))

      $($('.carousel-item').get(id)).removeClass("active")
      $($('.carousel-item').get((id+1)%$('.carousel-item').length)).addClass("active")
    },
    openCarousel: function(picture) {
      // console.log(picture)

      let carouselModal = new Modal(document.getElementById("pictures-carousel"), {
        keyboard: true
      })

      let id = $('.carousel-item').toArray().indexOf($('.carousel-item').toArray().find(c => c.className.includes("active")))
      let idPicture = $('.carousel-item').toArray().indexOf($('.carousel-item').toArray().find(c => c.innerHTML.includes(picture)))

      $($('.carousel-item').get(id)).removeClass("active")
      $($('.carousel-item').get(idPicture)).addClass("active")

      carouselModal.show()
    },
    rollingCarousel: function() {
      // setTimeout(() => {
      //   let id = $('.carousel-item').toArray().indexOf($('.carousel-item').toArray().find(c => c.className.includes("active")))
      //
      //   $($('.carousel-item').get(id)).removeClass("active")
      //   $($('.carousel-item').get((id+1)%$('.carousel-item').length)).addClass("active")
      //   this.rollingCarousel()
      // }, 10000)
    },
    find() {
      const that = this
      this.spinnerShow()

      Vue.axios({
        method: "POST",
        url: Config.api.url + "publicdata/references/find/" + that.$route.params.id,
      }).then((response) => {
        console.log(response.data)
        that.project = response.data
        // console.log(that.project)
        that.spinnerHide()

        that.rollingCarousel()
      })
    }
  },
  mounted() {
    this.find()

    this.maxHeight = window.innerHeight-50

  }
}
</script>

<style scoped>
  .picture-hover {
    transform: scale(1.0);
    transition: all 200ms;
  }

  .picture-hover:hover {
    transform: scale(1.08);
    transition: all 200ms;
    cursor: pointer;
    z-index: 10;
  }

  @media screen and (min-width: 1001px) {
    .col-lg-4 {
      width: 33%;
    }
  }

  @media screen and (max-width: 1000px) {
    .col-lg-4 {
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
    }
  }
</style>