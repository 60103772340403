<template>
  <section id="projects-head" class="bg-lightgrey" style="height: auto;">
    <div class="container" style="height: auto;">
      <div class="card card-body">
        <div class="row align-content-center align-items-center">
          <div class="col-lg-8 align-left">
            <h2 class="title-delagotic text-uppercase">{{ project._business !== undefined ? project._business._object : '' }}</h2>
            <h2 class="lead text-uppercase align-left">
              <i class="fas fa-hashtag"></i>
              {{ project._business !== undefined ? project._business._type  : ''}}
            </h2>
          </div>
          <div class="col-lg-4 align-right">
            <h4 class="lead text-uppercase">
              <i class="fas fa-location-arrow"></i>&nbsp;
              {{ project._business !== undefined ? project._business._city : '' }}
            </h4>
          </div>
        </div>
      </div>
      <br v-if="project._details !== '' && project._details !== null && project._details !== undefined">
      <div class="card card-body" v-if="project._details !== '' && project._details !== null && project._details !== undefined">
        <p v-html="project._details" style="text-align: justify"></p>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue";

const Config = require("../../apiconfig.json")

export default {
  name: "HeadSection",
  data: () => {
    return {
      project: {},
      Config
    }
  },
  methods: {
    find() {
      const that = this
      this.spinnerShow()

      Vue.axios({
        method: "POST",
        url: Config.api.url + "publicdata/references/find/" + that.$route.params.id,
      }).then((response) => {
        that.project = response.data
        that.spinnerHide()
      })
    }
  },
  mounted() {
    this.find()
  }
}
</script>

<style scoped>
@font-face {
  font-family: "Dela Gothic One Regular";
  src: url("../../../public/modules/fonts/DelaGothicOne-Regular.ttf") format("truetype")
}

.title-delagotic {
  font-family: "Dela Gothic One Regular", serif !important;
}

.bg-lightgrey {
  background-color: #F8F9FA !important;
}

.no-link {
  text-decoration: none;
  color: #6D696D;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}
</style>