<template>
    <section id="history" class="bg-lightgrey" :style="`min-height: ${screen.height}px`">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h2 class="text-uppercase">À propos de l'entreprise</h2>
            <h3 class="text-muted section-subheading">Découvrez l'entreprise <strong>ACT'BOIS</strong> en quelques points bien précis.</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <ul class="list-group timeline">
              <li class="list-group-item history-part">
                <div class="timeline-image">
                  <img class="rounded-circle img-fluid" :src="`${Config.api.url}assets/history/1.png`" alt="..." draggable="false">
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h4>2004</h4>
                    <h4 class="subheading text-uppercase">Création de l'entreprise</h4>
                  </div>
                  <div class="timeline-body">
                    <p class="text-muted text-justify">
                      Enrichi d'une expérience de {{ new Date().getFullYear()-2001 }} ans, acquise aux seins d'entreprises et de bureaux d'études, Damien PIERRAT a crée <strong>ACT'BOIS</strong> en 2004. Aujourd'hui avec son équipe, il met ses connaissances techniques et son sens pratique à votre service, pour vous accompagner dans la réalisation de vos projets bois et/ou métal.
                    </p>
                  </div>
                </div>
              </li>

              <li class="list-group-item timeline-inverted history-part">
                <div class="timeline-image">
                  <img class="rounded-circle img-fluid" :src="`${Config.api.url}assets/history/7.png`" alt="..." draggable="false">
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
<!--                    <h4>2007</h4>-->
                    <h4 class="subheading text-uppercase">Notre champ d'action</h4>
                  </div>
                  <div class="timeline-body">
                    <p class="text-muted text-justify">
                      Notre secteur d'activité est vaste. Il s'étend de l'habitation jusqu'aux grosses structures de l'industrie et des bâtiments publiques.
                    </p>
                  </div>
                </div>
              </li>

              <li class="list-group-item history-part">
                <div class="timeline-image">
                  <img class="rounded-circle img-fluid" :src="`${Config.api.url}assets/history/3.png`" alt="..." draggable="false">
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <!--                    <h4>2007</h4>-->
                    <h4 class="subheading text-uppercase">L'exécution</h4>
                  </div>
                  <div class="timeline-body">
                    <p class="text-muted text-justify">
                      Nous réalisons les dossiers de fabrication complets de vos ouvrages. Ils sont adaptés aux commandes numériques et aux méthodes de l'entreprise.
                    </p>
                  </div>
                </div>
              </li>

              <li class="list-group-item timeline-inverted history-part">
                <div class="timeline-image">
                  <img class="rounded-circle img-fluid" :src="`${Config.api.url}assets/history/4.png`" alt="..." draggable="false">
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <!--                    <h4>2007</h4>-->
                    <h4 class="subheading text-uppercase">Avec vous pour vos projets</h4>
                  </div>
                  <div class="timeline-body">
                    <p class="text-muted text-justify">
                      <strong>ACT'BOIS</strong> élabore avec tous les documents nécessaires à la réalisation de vos projets en bois ou métal. Il réalise des calculs précis, des méthodes constructives détaillées fixant les conditions de réalisation ou de réparation de vos ouvrages.
                    </p>
                  </div>
                </div>
              </li>

              <li class="list-group-item history-part">
                <div class="timeline-image">
                  <img class="rounded-circle img-fluid" :src="`${Config.api.url}assets/history/5.png`" alt="..." draggable="false">
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <!--                    <h4>2007</h4>-->
                    <h4 class="subheading text-uppercase">La maîtrise d'oeuvre</h4>
                  </div>
                  <div class="timeline-body">
                    <p class="text-muted text-justify">
                      <strong>ACT'BOIS</strong> collabore avec l'équipe de maîtrise d'oeuvre dans sa globalité pour mener à bien le projet architectural dans le respect des règlementations et des normes en vigueur.
                    </p>
                  </div>
                </div>
              </li>

              <li class="list-group-item timeline-inverted history-part">
                <div class="timeline-image">
                  <img class="rounded-circle img-fluid" :src="`${Config.api.url}assets/history/8.png`" alt="..." draggable="false">
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <!--                    <h4>2007</h4>-->
                    <h4 class="subheading text-uppercase">Une équipe à votre service</h4>
                  </div>
                  <div class="timeline-body">
                    <p class="text-muted text-justify">
                      Depuis 2004, <strong>ACT'BOIS</strong> met à votre disposition une équipe dynamique composée d'ingénieurs, économistes et dessinateurs.
                      <br><br>
                      Pour répondre efficacement à vos demandes, nos postes de travail sont équipés de logiciel de CAO, de calculs pour la modélisation 3D.
                    </p>
                  </div>
                </div>
              </li>

              <li class="list-group-item history-part">
                <div class="timeline-image">
                  <img class="rounded-circle img-fluid" :src="`${Config.api.url}assets/history/6.png`" alt="..." draggable="false">
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <!--                    <h4>2007</h4>-->
                    <h4 class="subheading text-uppercase">L'expertise</h4>
                  </div>
                  <div class="timeline-body">
                    <p class="text-muted text-justify">
                      <strong>ACT'BOIS</strong> donne une explication concrète à un comportement anormal de la construction et propose des solutions de réparation et de renforcement.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
const Config = require("../../apiconfig.json")

export default {
  name: "HistorySection",
  data: () => {
    return {
      screen: {
        height: 0,
        width: 0
      },
      Config
    }
  },
  mounted() {
    this.screen.height = window.innerHeight

    window.addEventListener("resize", () => {
      this.screen.height = window.innerHeight
    })
  }
}
</script>

<style src="../../../public/modules/bootstrap/css/bootstrap.min.css"></style>
<style src="../../../public/modules/fonts/font-awesome.min.css"></style>
<style src="../../../public/modules/fontawesome/css/all.min.css"></style>

<style scoped>
.bg-lightgrey {
  background-color: #F8F9FA !important;
}

.text-justify {
  text-align: justify !important;
}

.history-part {
  background: none !important;
  border: none !important;
}
</style>