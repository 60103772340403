<template>
  <section id="team" class="bg-lightgrey" :style="`min-height: ${screen.height}px`">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h2 class="text-uppercase section-heading">L'Équipe</h2>
          <h3 class="text-muted section-subheading">Nous sommes heureux de vous présenter l'équipe d'Act' Bois !</h3>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-sm-2"></div>
        <div class="col-sm-4">
          <div class="team-member">
            <img class="rounded-circle mx-auto teampic" :src="`${Config.api.url}assets/profiles/DAMIENPIERRAT.jpeg`" alt="..." draggable="false">
            <h4>Damien PIERRAT</h4>
            <p class="text-muted fw-bold">Fondateur</p>
            <p class="text-muted">Ingénieur IPF structure bois, 25 années d'expérience dans l'étude de structures BOIS</p>
            <ul class="list-inline social-buttons">
              <li class="list-inline-item">
<!--                <a href="#">-->
<!--                  <i class="fab fa-linkedin-in"></i>-->
<!--                </a>-->
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="team-member">
            <img class="rounded-circle mx-auto teampic" :src="`${Config.api.url}assets/profiles/MYRIAMPIERRAT.jpg`" alt="..." draggable="false">
            <h4>Myriam PIERRAT</h4>
            <p class="text-muted fw-bold">Administratif</p>
            <p class="text-muted">Conjointe, collaboratrice</p>
            <ul class="list-inline social-buttons">
              <li class="list-inline-item">
                <!--                <a href="#">-->
                <!--                  <i class="fab fa-linkedin-in"></i>-->
                <!--                </a>-->
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-2"></div>

        <div class="col-sm-3">
          <div class="team-member">
            <img class="rounded-circle mx-auto teampic" :src="`${Config.api.url}assets/profiles/NICOLASDOTAL.jpeg`" alt="..." draggable="false">
            <h4>Nicolas DOTAL</h4>
            <p class="text-muted fw-bold">Chef de projet</p>
            <p class="text-muted">Diplômé ingénieur ENSTIB, construction et expertise bois</p>
            <ul class="list-inline social-buttons">
              <li class="list-inline-item">
                <!--                <a href="#">-->
                <!--                  <i class="fab fa-linkedin-in"></i>-->
                <!--                </a>-->
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="team-member">
            <img class="rounded-circle mx-auto teampic" :src="`${Config.api.url}assets/profiles/PHILIPPEEBY.jpeg`" alt="..." draggable="false">
            <h4>Philippe EBY</h4>
            <p class="text-muted fw-bold">Dessinateur</p>
            <p class="text-muted">Formation de technicien d'études bâtiment, option dessin de projet</p>
            <ul class="list-inline social-buttons">
              <li class="list-inline-item">
                <!--                <a href="#">-->
                <!--                  <i class="fab fa-linkedin-in"></i>-->
                <!--                </a>-->
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="team-member">
            <img class="rounded-circle mx-auto teampic" :src="`${Config.api.url}assets/profiles/DAVIDPIERRAT.jpeg`" alt="..." draggable="false">
            <h4>David PIERRAT</h4>
            <p class="text-muted fw-bold">Chef de projet</p>
            <p class="text-muted">Projeteur, économiste</p>
            <ul class="list-inline social-buttons">
              <li class="list-inline-item">
                <!--                <a href="#">-->
                <!--                  <i class="fab fa-linkedin-in"></i>-->
                <!--                </a>-->
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="team-member">
            <img class="rounded-circle mx-auto teampic" :src="`${Config.api.url}assets/profiles/EMMANUELVALDENAIRE.jpeg`" alt="..." draggable="false">
            <h4>Emmanuel VALDENAIRE</h4>
            <p class="text-muted fw-bold">Chef de projet</p>
            <p class="text-muted">Diplômé ingénieur ENSTIB, Option construction</p>
            <ul class="list-inline social-buttons">
              <li class="list-inline-item">
                <!--                <a href="#">-->
                <!--                  <i class="fab fa-linkedin-in"></i>-->
                <!--                </a>-->
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="team-member">
            <img class="rounded-circle mx-auto teampic" :src="`${Config.api.url}assets/profiles/ANTOINEROUSSEL.jpeg`" alt="..." draggable="false">
            <h4>Antoine ROUSSEL</h4>
            <p class="text-muted fw-bold">Assistant chef de projet</p>
            <p class="text-muted">Diplômé ingénieur ENSTIB, Option construction</p>
            <ul class="list-inline social-buttons">
              <li class="list-inline-item">
                <!--                <a href="#">-->
                <!--                  <i class="fab fa-linkedin-in"></i>-->
                <!--                </a>-->
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const Config = require("../../apiconfig.json")

export default {
  name: "TeamSection",
  data: () => {
    return {
      screen: {
        height: 0,
        width: 0
      },
      Config
    }
  },
  mounted() {
    this.screen.height = window.innerHeight

    window.addEventListener("resize", () => {
      this.screen.height = window.innerHeight
    })
  }
}
</script>

<style src="../../../public/modules/bootstrap/css/bootstrap.min.css"></style>
<style src="../../../public/modules/fonts/font-awesome.min.css"></style>
<style src="../../../public/modules/fontawesome/css/all.min.css"></style>

<style scoped>
.bg-lightgrey {
  background-color: #F8F9FA !important;
}

@media screen and (max-width: 1000px) {
  .responsive-button {
    width: 100%;
  }
}

.teampic {
  object-fit: cover !important;
}
</style>