<template>
  <section id="projects" class="bg-lightgrey" :style="`min-height: ${screen.height}px; padding-top: 0;`">
    <div class="container">
      <Content></Content>
      <gallery></gallery>
    </div>
  </section>
</template>

<script>
import Vue from "vue";

const Config = require("../../apiconfig.json")

import Content from "@/components/View/components/Content";
import Gallery from "@/components/View/components/Gallery";

export default {
  name: "ViewSection",
  components: {
    Content,
    Gallery
  },
  data: () => {
    return {
      screen: {
        height: 0,
        width: 0
      },
      Config,
      filter: "",
      project: {}
    }
  },
  methods: {
    find() {
      const that = this
      this.spinnerShow()

      Vue.axios({
        method: "POST",
        url: Config.api.url + "publicdata/references/find/" + that.$route.params.id,
      }).then((response) => {
        that.project = response.data
        that.spinnerHide()
      })
    }
  },
  watch: {
    $route() {

    }
  },
  mounted() {
    this.screen.height = window.innerHeight

    window.addEventListener("resize", () => {
      this.screen.height = window.innerHeight
    })

    window.addEventListener("hashchange", () => {
      this.filter = window.location.href
    })

    this.find()
  }
}
</script>

<style src="../../../public/modules/bootstrap/css/bootstrap.min.css"></style>
<style src="../../../public/modules/fonts/font-awesome.min.css"></style>
<style src="../../../public/modules/fontawesome/css/all.min.css"></style>

<style scoped>
@font-face {
  font-family: "Dela Gothic One Regular";
  src: url("../../../public/modules/fonts/DelaGothicOne-Regular.ttf") format("truetype")
}

.title-delagotic {
  font-family: "Dela Gothic One Regular", serif !important;
}

.bg-lightgrey {
  background-color: #F8F9FA !important;
}

.align-left {
  text-align: left;
}
</style>