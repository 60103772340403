<template>
  <div id="app">
    <router-view/>

    <div class="modal fade" id="spinnerModal" style="z-index: 4000 !important;" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered bg-none" role="document">
        <div class="modal-content bg-none">
          <div class="modal-body text-center bg-none">
            <div class="w-100 d-flex justify-content-center align-content-center">
              <scaling-squares-spinner
                  :animation-duration="2000"
                  :size="100"
                  color="#C7D43F"/>
            </div>
            <button class="btn btn-outline-danger btn-sm mt-5" @click="cancelLoad" style="border-color: #C7D43F; color: #C7D43F">
              <i class="fas fa-times"></i>&nbsp;
              Annuler
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import $ from "jquery";

const Config = require("./apiconfig.json")

// import { BreedingRhombusSpinner } from 'epic-spinners'
import { ScalingSquaresSpinner  } from 'epic-spinners'


export default {
  name: 'App',
  components: {
    // BreedingRhombusSpinner
    ScalingSquaresSpinner
  },
  data: function() {
    return {
      Config,
      date: new Date(Config.env.release),
      now: new Date()
    }
  },
  methods: {
    cancelLoad: function() {
      this.spinnerHide()
    },

    check: function() {
      if (process.env.NODE_ENV === "production") {
        // let moment = new Date(this.date.getTime()-this.now.getTime())
        if (this.date.getTime()-this.now.getTime() > 0) {
          this.$router.push("/countdown")
        }
      }
    }
  },
  mounted() {
    this.check()
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

* {
  user-select: none;
}

.bg-none {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.bg-actbois {
  background: #C7D43F !important;
}

.btn-actbois {
  background-color: #C7D43F !important;
  color: #FFFFFF !important;
  transition: background-color 200ms;
}

.btn-actbois:hover {
  background-color: #B7C437 !important;
  transition: background-color 200ms;
  color: #FFFFFF !important;
}

.nav-link:hover {
  color: #C7D43F !important;
}

.text-actbois {
  color: #C7D43F;
}

.btn-outline-actbois {
  /*border-color: #C7D43F;*/
  border: 1px solid #C7D43F !important;
  color: #C7D43F !important;
}

.btn-outline-actbois:hover {
  background-color: #C7D43F;
  border-color: #C7D43F;
  color: #FFFFFF !important;
}

.btn:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 .25rem rgba(199,212,63,.25) !important;
}

a:hover {
  color: #C7D43F !important;
}
</style>
