<template>
  <div class="container mb-3">
    <div class="row">
      <div :class="`col-lg-${project._notes === '' || project._notes === undefined || project._notes === null ? '6' : '4'}`">
        <div class="card card-body align-left mb-2 no-places align-left" v-if="project._projectOwner !== undefined && project._projectOwner !== null && project._projectOwner !== false">
          <h6 class="title-delagotic">Maître d'ouvrage</h6>
          <h6 class="lead text-center">{{ project._projectOwner !== undefined ? project._projectOwner._detailname : '' }}</h6>
        </div>
        <div class="card card-body align-left mb-2 no-places" v-if="project._projectManager !== undefined && project._projectManager !== null && project._projectManager !== false">
          <h6 class="title-delagotic">Maître d'oeuvre</h6>
          <h6 class="lead text-center">{{ project._projectManager !== undefined ? project._projectManager._detailname : '' }}</h6>
        </div>

        <div class="card card-body align-left mb-2 no-places" v-if="project._carpenter !== undefined && project._carpenter !== null && project._carpenter !== false">
          <h6 class="title-delagotic">Charpentier</h6>
          <h6 class="lead text-center">{{ project._carpenter !== undefined ? project._carpenter._detailname : '' }}</h6>
        </div>

        <div class="card card-body align-left mb-2 no-places" v-if="project._pictureCredit !== undefined && project._pictureCredit !== null && project._pictureCredit !== ''">
          <h6 class="title-delagotic">Crédit photos</h6>
          <h6 class="lead text-center text-uppercase">{{ project._pictureCredit }}</h6>
        </div>
      </div>
      <div class="col-lg-4" v-if="project._notes !== '' && project._notes !== undefined && project._notes !== null">
        <div class="card card-body mb-2 no-places h-100">
          <h6 class="title-delagotic">Notes supplémentaires</h6>
          <p class="" style="text-align: justify" v-html="project._notes">

          </p>
        </div>
      </div>
      <div :class="`col-lg-${project._notes === '' || project._notes === undefined || project._notes === null ? '6' : '4'}`">
        <div class="row mb-2">
          <div class="col-lg-6">
            <div class="card card-body no-places">
              <h6 class="title-delagotic text-center">Coût du projet</h6>
              <h6 class="text-center lead">{{ !isNaN(parseFloat(project._cost)) ? withRegex(parseFloat(project._cost).toFixed(2)) + " €" : 'N / C' }}</h6>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card card-body no-places">
              <h6 class="title-delagotic text-center">SHON</h6>
              <h6 class="text-center lead">{{ !isNaN(parseFloat(project._SHON)) ? withRegex(parseFloat(project._SHON).toFixed(2))  + " €": 'N / C' }}</h6>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-lg-6">
            <div class="card card-body no-places">
              <h6 class="title-delagotic text-center">Budget du lot</h6>
              <h6 class="text-center lead">{{ !isNaN(parseFloat(project._lotBudget)) ? withRegex(parseFloat(project._lotBudget).toFixed(2)) + " €" : 'N / C' }}</h6>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card card-body no-places">
              <h6 class="title-delagotic text-center">Surface</h6>
              <h6 class="text-center lead">{{ !isNaN(parseFloat(project._surface)) ? withRegex(parseFloat(project._surface).toFixed(2)) : 'N / C' }} <span v-if="!isNaN(parseFloat(project._surface))">m<sup>2</sup></span></h6>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="card card-body align-left mb-2 no-places">
            <h6 class="title-delagotic text-center">Année de livraison</h6>
            <h6 class="lead text-center">{{ project._deliveryYear }}</h6>
          </div>
          <div class="card card-body align-left mb-2 no-places" v-if="project._missions !== undefined && project._missions.length > 0">
            <h6 class="title-delagotic text-center">Missions réalisées</h6>
            <h6 class="lead text-center">{{ project._missions !== undefined && project._missions.length === 3 ? 'Toutes (BASE, EXE, PAC)' : project._missions.toString().replace("[", "").replace("]", "").replace(",", ", ") }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

const Config = require("../../../apiconfig.json")

export default {
  name: "Gallery",
  data: () => {
    return {
      Config,
      project: {},
    }
  },
  methods: {
    withRegex: function(value) {
      let final = ""
      let array = value.split(".")
      let unitString = array[0].split("").reverse().join("")

      for (let i=0; i<unitString.length; i+=3) {
        if (i === 0) {
          final = unitString.substring(i, i+3).split("").reverse().join("") + "" + final
        } else {
          final = unitString.substring(i, i+3).split("").reverse().join("") + " " + final
        }

        // console.log("STR : " + unitString.substring(i, i+3))
      }

      final = final + "." + array[1]

      // return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")
      // return value.toString()
      return final
    },
    find() {
      const that = this
      this.spinnerShow()

      Vue.axios({
        method: "POST",
        url: Config.api.url + "publicdata/references/find/" + that.$route.params.id,
      }).then((response) => {
        that.project = response.data
        that.spinnerHide()
      })
    }
  },
  mounted() {
    this.find()


  }
}
</script>

<style scoped>
  @font-face {
    font-family: "Dela Gothic One Regular";
    src: url("../../../../public/modules/fonts/DelaGothicOne-Regular.ttf") format("truetype")
  }

  .title-delagotic {
    font-family: "Dela Gothic One Regular", serif !important;
  }

  .no-places {
    border: none;
    background: transparent;
  }

  .align-left {
    text-align: left;
  }

  .btn-primary-opac {
    background-color: rgba(255, 209, 53, 0.7);
  }


</style>