<template>
  <header class="masthead bg-grey border-bottom" id="sumup" :style="`min-height: ${screen.height}px`">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="intro-text">
            <div class="intro-lead-in intro-heading text-actbois title-delagotic fs-custom">
              <span class="fs-custom">Qui sommes-nous ?</span>
            </div>
            <center><div class="lead text-white font-bold text-center" style="width: 85%;">
              <span>
                <strong>Act'Bois</strong> est un bureau d’études qualifié, certifié et reconnu pour réaliser des dossiers d'avant projet, d'exécution et de fabrication sur tout type de bâtiments et ouvrages d'art en structure bois et métalliques.
              </span>
            </div></center>
            <br>
            <a class="btn btn-actbois btn-xl text-uppercase" role="button" href="#history">
              À propos&nbsp;
              <i class="fas fa-arrow-down"></i>
            </a>
          </div>
        </div>
        <div class="col-lg-6 d-flex">
          <div class="d-flex justify-content-center align-items-center">
            <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators" :data-bs-slide-to="fIndex" :class="`${files.indexOf(file) === 0 ? 'active' : ''}`" :aria-current="`${files.indexOf(file) === 0 ? 'true' : 'false'}`" aria-label="Slide 1" v-for="(file, fIndex) of files" :key="fIndex"></button>
<!--                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>-->
<!--                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>-->
              </div>
              <div class="carousel-inner">
                <div :class="`carousel-item ${files.indexOf(file) === 0 ? 'active' : ''}`" v-for="(file, fIndex) of files" :key="fIndex">
                  <img :src="`${Config.api.url}referencespictures/${file}`" class="d-block w-100" alt="..." style="border-radius: 16px !important; max-height: 40rem; object-fit: cover;">
                </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
  </header>
</template>

<script>
// import $ from "jquery";

const Config = require("../../apiconfig.json")

import Vue from "vue";

export default {
  name: "WelcomeSection",
  data: () => {
    return {
      screen: {
        height: 0,
        width: 0
      },
      Config,
      files: []
    }
  },
  methods: {
    getElements: function() {
      const that = this

      Vue.axios({
        method: "GET",
        url: Config.api.url + "references/getpictures/6"
      }).then((response) => {
        if (response.data !== false && response.data.length > 0) {
          that.files = response.data
        }
      })
    }
  },
  mounted() {
    this.screen.height = window.innerHeight

    window.addEventListener("resize", () => {
      this.screen.height = window.innerHeight
    })

    this.getElements()
  }
}
</script>

<style src="../../../public/modules/bootstrap/css/bootstrap.min.css"></style>
<style src="../../../public/modules/fonts/font-awesome.min.css"></style>
<style src="../../../public/modules/fontawesome/css/all.min.css"></style>

<style scoped>
  @font-face {
    font-family: "Dela Gothic One Regular";
    src: url("../../../public/modules/fonts/DelaGothicOne-Regular.ttf") format("truetype")
  }

  .title-delagotic {
    font-family: "Dela Gothic One Regular", serif !important;
  }

  .fs-custom {
    font-size: 3rem;
  }

  .bg-grey {
    background-color: #6D696D !important;
  }

  .text-orange {
    color: #FFD72F !important;
  }

  .font-bold {
    font-weight: bold;
  }

  .text-justify {
    text-align: justify;
  }
</style>