<template>
  <section id="projects-head" class="bg-lightgrey" style="height: auto; padding-bottom: 3%;">
    <div class="container" style="height: auto;">
      <div class="card card-body">
        <div class="row justify-content-center align-items-center align-content-center">
          <div class="col-auto">
            <a :class="`lead no-link text-uppercase ${$route.fullPath.includes('OTHER') ? 'btn btn-actbois text-white' : ''}`" href="#OTHER">
              Autre
            </a>
          </div>
          <div class="col-auto">
            <a :class="`lead no-link text-uppercase ${$route.fullPath.includes('BUSINESS') ? 'btn btn-actbois text-white' : ''}`" href="#BUSINESS">
              Commerce
            </a>
          </div>
          <div class="col-auto">
            <a :class="`lead no-link text-uppercase ${$route.fullPath.includes('COMPAGNY') ? 'btn btn-actbois text-white' : ''}`" href="#COMPAGNY">
              Industrie
            </a>
          </div>
          <div class="col-auto">
            <a :class="`lead no-link text-uppercase ${$route.fullPath.includes('HOME') ? 'btn btn-actbois text-white' : ''}`" href="#HOME">
              Logement
            </a>
          </div>
          <div class="col-auto">
            <a :class="`lead no-link text-uppercase ${$route.fullPath.includes('HEALTH') ? 'btn btn-actbois text-white' : ''}`" href="#HEALTH">
              Santé
            </a>
          </div>
          <div class="col-auto">
            <a :class="`lead no-link text-uppercase ${$route.fullPath.includes('SCHOOL') ? 'btn btn-actbois text-white' : ''}`" href="#SCHOOL">
              Scolaire
            </a>
          </div>
          <div class="col-auto">
            <a :class="`lead no-link text-uppercase ${$route.fullPath.includes('SOCIAL') ? 'btn btn-actbois text-white' : ''}`" href="#SOCIAL">
              Social
            </a>
          </div>
          <div class="col-auto">
            <a :class="`lead no-link text-uppercase ${$route.fullPath.includes('SPORT') ? 'btn btn-actbois text-white' : ''}`" href="#SPORT">
              Sportif
            </a>
          </div>
          <div class="col-auto">
            <a :class="`lead no-link text-uppercase ${$route.fullPath.includes('TERTIARY') ? 'btn btn-actbois text-white' : ''}`" href="#TERTIARY">
              Tertiaire
            </a>
          </div>
          <div class="col-auto">
            <a :class="`lead no-link text-uppercase ${$route.fullPath.includes('ALL') ? 'btn btn-actbois text-white' : ''}`" href="#ALL">
              Tout
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeadSection"
}
</script>

<style scoped>
  .bg-lightgrey {
  background-color: #F8F9FA !important;
  }

  .no-link {
    text-decoration: none;
    color: #6D696D;
  }
  
  @media screen and (max-width: 1000px) {
    .col-auto {
      width: 46%;
      margin-left: 2%;
      margin-right: 2%;
    }
  }
</style>