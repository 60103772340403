<template>
  <div class="projects">
    <navbar></navbar>
    <head-section></head-section>
    <view-section></view-section>
    <footer-section></footer-section>
  </div>
</template>

<script>
import Navbar from "../components/Home/Navbar";
const Config = require("../apiconfig.json")

import HeadSection from "../components/View/HeadSection";
import ViewSection from "../components/View/ViewSection";
import FooterSection from "@/components/Home/FooterSection";

export default {
  name: 'ProjectView',
  components: {
    Navbar,
    HeadSection,
    ViewSection,
    FooterSection,
  },
  data: function() {
    return {
      Config
    }
  },
  mounted() {
    let section=this.$router.currentRoute.hash.replace("#", "");
    if (section) this.$nextTick(()=> window.document.getElementById(section).scrollIntoView());

    window.document.getElementById("projects-head").scrollIntoView()
  }
}
</script>

<style src="../../public/modules/bootstrap/css/bootstrap.min.css"></style>
<!--<style src="../../public/modules/fonts/font-awesome.min.css"></style>-->
<style src="../../public/modules/fontawesome/css/all.min.css"></style>

<style>
@font-face {
  font-family: "Dela Gothic One Regular";
  src: url("../../public/modules/fonts/DelaGothicOne-Regular.ttf") format("truetype")
}

.title-delagotic {
  font-family: "Dela Gothic One Regular", serif !important;
}
</style>
