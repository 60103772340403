<template>
  <section id="projects" class="bg-lightgrey" :style="`min-height: ${screen.height}px; padding-top: 0;`">
    <div class="container">
<!--      <div class="row">-->
<!--        <div class="col-lg-12 text-center">-->
<!--          <h2 class="text-uppercase section-heading text-dark">Nos projets</h2>-->
<!--          <h3 class="text-muted section-subheading text-black-50">Découvrez nos meilleurs projets, réalisés à la main par l'équipe Act' Bois</h3>-->
<!--        </div>-->
<!--      </div>-->
      <div class="row justify-content-center align-items-center align-content-center">
        <div class="col-lg-4 little-margin col-lg-4-30" v-for="(project, pIndex) of projects" :key="pIndex">
          <div class="project-card">
            <div class="project-item no-margin on-mobile" :style="`background-image: url('${Config.api.url}assets/BANNER_LOGO.png'); background-size: cover;`" @click="$router.push('/projects/' + project._id)" v-if="project._flag.length === 0">
<!--            <div class="project-item" @click="$router.push('/projects/' + project._id)">-->
<!--              <img :src="`${Config.api.url}assets/header2.jpg`" style="object-fit: cover; max-width: 100%;" alt="..." v-if="project._flag.length === 0">-->
<!--              <img :src="`${Config.api.url}referencespictures/${project._flag[Math.floor(Math.random()*project._flag.length)].picture}`" style="object-fit: cover; max-width: 100%;" alt="..." v-else>-->
            </div>
            <div class="project-item" :style="`background-image: url('${Config.api.url}referencespictures/${project._flag[Math.floor(Math.random()*project._flag.length)].picture}'); background-size: cover;`" @click="$router.push('/projects/' + project._id)" v-else>
            </div>
            <div class="project-detail">
              <h2 class="project-title text-uppercase">{{ project._business !== undefined ? project._business._object : '' }}</h2>
              <p class="lead text-uppercase text-left">
                <i class="fas fa-location-arrow"></i>&nbsp;
                {{ project._business !== undefined ? project._business._city : '' }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue";

const Config = require("../../apiconfig.json")

export default {
  name: "ProjectsSection",
  data: () => {
    return {
      screen: {
        height: 0,
        width: 0
      },
      Config,
      filter: "",
      projects: [],
      tampon: []
    }
  },
  watch: {

  },
  methods: {
    findAll() {
      const that = this
      this.spinnerShow()

      Vue.axios({
        method: "POST",
        url: Config.api.url + "publicdata/references/findAll"
      }).then((response) => {
        that.projects = response.data
        that.tampon = that.projects
        console.log("PROJECTS")
        that.spinnerHide()
      })
    }
  },
  mounted() {
    this.screen.height = window.innerHeight

    window.addEventListener("resize", () => {
      this.screen.height = window.innerHeight
    })

    window.addEventListener("hashchange", () => {
      let section = this.$router.currentRoute.hash.replace("#", "");

      let values = this.tampon

      switch (section) {
        case "OTHER":
          this.projects = values.filter(v => v._business._type === 'Autre')
          break;
        case "BUSINESS":
          this.projects = values.filter(v => v._business._type === 'Commerce')
          break;
        case "COMPAGNY":
          this.projects = values.filter(v => v._business._type === 'Industrie')
          break;
        case "HOME":
          this.projects = values.filter(v => v._business._type === 'Logement')
          break;
        case "HEALTH":
          this.projects = values.filter(v => v._business._type === 'Santé')
          break;
        case "SCHOOL":
          this.projects = values.filter(v => v._business._type === 'Scolaire')
          break;
        case "SOCIAL":
          this.projects = values.filter(v => v._business._type === 'Social')
          break;
        case "SPORT":
          this.projects = values.filter(v => v._business._type === 'Sportif')
          break;
        case "TERTIARY":
          this.projects = values.filter(v => v._business._type === 'Tertiaire')
          break;
        default:
          this.projects = values
          break;
      }

      console.log(section)
    })

    this.findAll()
  }
}
</script>

<style src="../../../public/modules/bootstrap/css/bootstrap.min.css"></style>
<style src="../../../public/modules/fonts/font-awesome.min.css"></style>
<style src="../../../public/modules/fontawesome/css/all.min.css"></style>

<style scoped>
.bg-lightgrey {
  background-color: #F8F9FA !important;
}

.text-left {
  text-align: left;
}

.no-margin {
  margin: 0 !important;
  padding: 0 !important;
}

.little-margin {
  margin: 5px;
  padding: 0;
}

@media screen and (max-width: 1000px) {
  .responsive-button {
    width: 100%;
  }

  .col-lg-4-30 {
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
  }

  .on-mobile {
    background-image: url("https://api.actbois.org/assets/LOGO.png") !important;
  }

  .project-item {
    height: auto;
    width: 100%;
    /*margin: 1%;*/
    background-position: center;
    max-width: 100%;
    background-repeat: no-repeat;
    /*border: 2px solid transparent;*/
    transition: all 200ms;
    position: absolute;
    z-index: 4;
    min-height: 20rem;
    transform: scale(1.0);
    opacity: 0.3;
  }

  .project-card {
    position: relative;
    min-height: 20rem;
  }

  .project-detail {
    display: block;
    opacity: 1;
    transition: all 200ms;
    position: absolute;
    z-index: 5;
    margin: 5%;
    max-width: 80%;
  }

  .project-item:hover {
    /*border: 2px solid #FFD72F;*/
    opacity: 0.3;
    transition: all 200ms;
    cursor: pointer;
    transform: scale(1.05);
    z-index: 10
  }

  .project-item:hover + .project-detail {
    opacity: 1;
    transition: all 200ms;
  }

  .project-title {
    text-align: left;
    color: #000000;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1001px) {
  .col-lg-4-30 {
    width: 30%;
  }

  .project-item {
    height: auto;
    width: 100%;
    /*margin: 1%;*/
    background-position: center;
    max-width: 100%;
    background-repeat: no-repeat;
    /*border: 2px solid transparent;*/
    transition: all 200ms;
    position: absolute;
    z-index: 4;
    min-height: 20rem;
    transform: scale(1.0);
  }

  .project-card {
    position: relative;
    min-height: 20rem;
  }

  .project-detail {
    display: block;
    opacity: 0;
    transition: all 200ms;
    position: absolute;
    z-index: 5;
    margin: 5%;
  }

  .project-item:hover {
    /*border: 2px solid #FFD72F;*/
    opacity: 0.3;
    transition: all 200ms;
    cursor: pointer;
    transform: scale(1.05);
    z-index: 10
  }

  .project-item:hover + .project-detail {
    opacity: 1;
    transition: all 200ms;
  }

  .project-title {
    text-align: left;
    color: #000000;
    margin-bottom: 0;
  }
}
</style>