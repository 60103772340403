import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import "bootstrap"

import GTags from "./GTags.json"

import VueGtag from "vue-gtag"

Vue.use(VueGtag, {
  config: { id: GTags.id }
}, router);

import $ from "jquery";

Vue.mixin({
  methods: {
    spinnerHide: function() {
      $('#spinnerModal').addClass("fade").removeClass("show").css("display", "none")
      // $('#spinnerModal').fadeOut()

      // $('#spinnerModal').modal("hide");
      // $('#spinnerModal').hide();
      // $('#spinnerModal').fadeOut();

      // $('#spinnerModal').removeClass("show").attr("style", "z-index: 4000 !important")
      // console.log("test")
    },
    spinnerShow: function() {
      $('#spinnerModal').removeClass("fade").addClass("show").css("display", "block")
      // $('#spinnerModal').fadeIn()

      // $('#spinnerModal').modal()
      // $('#spinnerModal').addClass("show").attr("style", "z-index: 4000 !important; display: block;")
    }
  }
})

new Vue({
  router,
  store,

  render: h => h(App)
}).$mount('#app')
