<template>
  <header class="masthead header-bg" id="page-top" :style="`background-image: url('${Config.api.url}assets/header4.bmp') !important; margin: 0; background-size: cover; min-height: ${screen.height}px`">
    <div :class="`container`" :style="`height: ${screen.height}px`">
      <div class="intro-text h-100 d-flex flex-column-reverse align-items-center">
<!--        <div class="intro-lead-in"><span>Welcome To Our Studio!</span></div>-->
<!--        <div class="intro-heading text-uppercase"><span>It's Nice To Meet You</span></div>-->
            <a class="btn btn-actbois btn-xl text-uppercase text-white responsive-button" role="button" href="#sumup">
              Présentation&nbsp;
              <i class="fas fa-chevron-down"></i>
            </a>
      </div>
    </div>
  </header>
</template>

<script>
const Config = require("../../apiconfig.json")

import Vue from "vue";
import $ from "jquery"

export default {
  name: "HeaderSection",
  data: () => {
    return {
      Config,
      screen: {
        height: 0,
        width: 0
      },
      files: []
    }
  },
  methods: {
    updateWallpaper() {
      const that = this

      setTimeout(() => {
        Vue.axios({
          method: "GET",
          url: Config.api.url + "references/getpictures/null"
        }).then((response) => {
          if (response.data !== false && response.data.length > 0) {
            that.files = response.data
            // $('#page-top').fadeTo("slow", 0.3, function() {
            //   $(this).css("background-image", 'url(' + Config.api.url + 'referencespictures/' + that.files[Math.floor(Math.random()*that.files.length)] + ')')
            //   $(this).css("opacity", '1')
            // }).delay(1000).fadeTo("slow", 1)
            $(this)

            document.getElementById("page-top").style.backgroundImage = `url('${Config.api.url}referencespictures/${that.files[Math.floor(Math.random()*that.files.length)]}')`;

            that.updateWallpaper()
          }
        })
      }, 7000)
    }
  },
  mounted() {
    this.screen.height = window.innerHeight

    window.addEventListener("resize", () => {
      this.screen.height = window.innerHeight
    })

    this.updateWallpaper()
  }
}
</script>

<style src="../../../public/modules/bootstrap/css/bootstrap.min.css"></style>
<style src="../../../public/modules/fonts/font-awesome.min.css"></style>
<style src="../../../public/modules/fontawesome/css/all.min.css"></style>

<style scoped>
  @font-face {
    font-family: "Dela Gothic One Regular";
    src: url("../../../public/modules/fonts/DelaGothicOne-Regular.ttf") format("truetype")
  }

  .title-delagotic {
    font-family: "Dela Gothic One Regular", serif !important;
  }

  #page-top {
    transition: background 1s linear;
  }

  @media screen and (max-width: 1000px) {
    .responsive-button {
      width: 75%;
    }
  }

  @media screen and (min-width: 1001px) {
    .responsive-button {
      width: 25%;
    }
  }
</style>