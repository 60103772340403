<template>
  <div class="home">
    <navbar></navbar>
    <header-section></header-section>
    <welcome-section></welcome-section>
    <history-section></history-section>
    <team-section></team-section>
    <projects-section></projects-section>
<!--    <contact-section></contact-section>-->
    <footer-section></footer-section>
  </div>
</template>

<script>
import Navbar from "../components/Home/Navbar";
const Config = require("../apiconfig.json")

import HeaderSection from "../components/Home/HeaderSection";
import WelcomeSection from "../components/Home/WelcomeSection";
import TeamSection from "../components/Home/TeamSection";
// import ContactSection from "../components/Home/ContactSection";
import ProjectsSection from "../components/Home/ProjectsSection";
import HistorySection from "@/components/Home/HistorySection";
import FooterSection from "@/components/Home/FooterSection";

export default {
  name: 'Home',
  components: {
    Navbar,
    HeaderSection,
    WelcomeSection,
    HistorySection,
    TeamSection,
    // ContactSection,
    ProjectsSection,
    FooterSection,
  },
  data: function() {
    return {
      Config,
      date: new Date(Config.env.release),
      now: new Date(),
    }
  },
  methods: {

  },
  mounted() {
    let section=this.$router.currentRoute.hash.replace("#", "");
    if (section) this.$nextTick(()=> window.document.getElementById(section).scrollIntoView());
  }
}
</script>

<style src="../../public/modules/bootstrap/css/bootstrap.min.css"></style>
<!--<style src="../../public/modules/fonts/font-awesome.min.css"></style>-->
<style src="../../public/modules/fontawesome/css/all.min.css"></style>

<style>
  @font-face {
    font-family: "Dela Gothic One Regular";
    src: url("../../public/modules/fonts/DelaGothicOne-Regular.ttf") format("truetype")
  }

  .title-delagotic {
    font-family: "Dela Gothic One Regular", serif !important;
  }
</style>
